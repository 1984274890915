import { configureStore } from '@reduxjs/toolkit'
import general from "../actions/general"

const store = configureStore({
    reducer: {
        general,
    },
})

// const createHistory = require('history').createBrowserHistory
// const history = createHistory()


export default store