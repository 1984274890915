import React from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'

export default function Footer() {
  const navigate = useNavigate();

  const navigateOnClick = (ref) => {
    navigate('/', { state: { ref } });
  }

  return (
    <div className='footer-wrapper'>
      {/* <div className='section mobile'>
        <div className='footer-nav' onClick={() => navigateOnClick('activityRef')}>ДЕЙНОСТ</div>
        <div className='footer-nav' onClick={() => navigateOnClick('aboutRef')}>ЗА НАС</div>
        <div className='footer-nav' onClick={() => navigateOnClick('newsRef')}>НОВИНИ</div>
        <div className='footer-nav' onClick={() => navigateOnClick('infoRef')}>ПОЛЕЗНА ИНФОРМАЦИЯ</div>
      </div> */}
      <div className='section margin'>
        <div className='footer-link'>ПОЛИТИКА НА БИСКВИТКИТЕ</div>
        <div className='footer-link' onClick={() => window.open('https://graystone.s3.eu-central-1.amazonaws.com/electricity.png')}>ЛИЦЕНЗ ЗА ТЪРГОВИЯ С ЕЛЕКТРИЧЕСКА ЕНЕРГИЯ</div>
        <div className='footer-link' onClick={() => window.open('https://graystone.s3.eu-central-1.amazonaws.com/gas.png')}>ЛИЦЕНЗ ЗА ТЪРГОВИЯ С ПРИРОДЕН ГАЗ</div>
      </div>
    </div>
  )
}
