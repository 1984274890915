import React from "react";
import "./styles.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FsLightbox from "fslightbox-react";
// import Lightbox from "react-18-image-lightbox";
// import SimpleReactLightbox from "simple-react-lightbox";

export default function SingleNews() {
  // todo: get id from url and make request to get single news data to display
  const navigate = useNavigate();
  const [toggler, setToggler] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [ imageIndex, setImageIndex ] = useState(0);

  const newsData = [
    { text: 'Заглавие на новината се изписва тук, като то може да бъде и по-дълго от два реда', date: '12.01.2022', img: 'section-4-1.png' },
    { text: 'Примерно заглавие на новина, която ще бъде свързана с енергия', date: '12.01.2022', img: 'section-4-2.png' },
    { text: 'Заглавие на новината се изписва тук', date: '12.01.2022', img: 'section-4-3.png' },
    { text: 'Примерно заглавие на новина', date: '12.01.2022', img: 'section-4-4.png' }
]

const  lightBoxHandler  = (state, imageIndex) => {
  setToggler(state);
  setImageIndex(imageIndex);
};


const handleImageOpen = (index) => {
    setToggler(!toggler)
    setImageIndex(index + 1)
}

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  return (
    <div className="screen-wrapper single-news">
      <div className="column padding">
        <div className="top-button-wrapper" onClick={() => navigate(-1)}>
          <img src={require('../../assets/icons/arrow-icon-blue.svg').default} className='arrow-icon' />
          <div className="text">Назад</div>
        </div>

        <h3>
          Заглавие на новината се изписва тук, като то може да бъде и по-дълго
          от два реда
        </h3>
        <p>
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
          qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
          dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
          quia non numquam eius modi tempora incidunt ut labore et dolore magnam
          aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
          exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex
          ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
          ea voluptate velit esse quam nihil molestiae consequatur, vel illum
          qui dolorem eum fugiat quo voluptas nulla pariatur?"
        </p>
        <p>
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
          qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
          dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
          quia non numquam eius modi tempora incidunt ut labore et dolore magnam
          aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
          exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex
          ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
          ea voluptate velit esse quam nihil molestiae consequatur, vel illum
          qui dolorem eum fugiat quo voluptas nulla pariatur?"
        </p>
      </div>
      <div className="column images desktop">
        <div className={`images-wrapper ${newsData.length === 3 ? 'unset' : ''}`}>
          {newsData?.map((el, index) => {
              return <img src={require(`../../assets/images/${el.img}`)} className={`news-image ${newsData.length > 3 ? 'half-width' : (newsData.length === 3 && (index === 1 || index === 2)) ? 'half-width' : 'full-width'}`} onClick={() => handleImageOpen(index)} key={`${el.img}-${index}-${el.date}`} />

            //   return <a data-fslightbox href={require(`../../assets/images/${el.img}`)}>
            //           <img src={require(`../../assets/images/${el.img}`)} className='news-image' key={`${el.img}-${index}-${el.date}`} />
            //          </a>
           })}

           <img src={require("../../assets/icons/full-screen-icon.svg").default} onClick={() => handleImageOpen(0)} className="fill-screen-icon" />
        </div>

        {/* <Lightbox
            mainSrc={newsData[photoIndex].img}
            nextSrc={newsData[(photoIndex + 1) % newsData.length].img}
            prevSrc={newsData[(photoIndex + newsData.length - 1) % newsData.length].img}
            // onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + newsData.length - 1) % newsData.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % newsData.length)
            }
          /> */}

        {/* <LightBox
				state={toggler}
        event={lightBoxHandler}
        data={[
          <div><img src={require("../../assets/images/section-4-1.png")} /></div>,
          <div><img src={require("../../assets/images/section-4-2.png")} /></div>,
          <div><img src={require("../../assets/images/section-4-3.png")} /></div>,
          <div><img src={require("../../assets/images/section-4-4.png")} /></div>,
          <div><img src={require("../../assets/images/section-4-4.png")} /></div>,
          <div><img src={require("../../assets/images/section-4-4.png")} /></div>,
      ]}
        imageWidth="60vw"
        imageHeight="70vh"
        thumbnailHeight={50}
        thumbnailWidth={50}
        setImageIndex={setImageIndex}
        imageIndex={imageIndex}
			/> */}

        <FsLightbox
			      toggler={toggler}
            type="image"
            slide={imageIndex}
			      sources={[
                <div><img src={require("../../assets/images/section-4-1.png")} /></div>,
                <div><img src={require("../../assets/images/section-4-2.png")} /></div>,
                // <div><img src={require("../../assets/images/section-4-3.png")} /></div>,
                <div><img src={require("../../assets/images/section-4-4.png")} /></div>,
                <div><img src={require("../../assets/images/section-4-4.png")} /></div>,
                <div><img src={require("../../assets/images/section-4-4.png")} /></div>,
			      ]}
            thumbs={[
               // null, // The thumb will be created automatically.
                <div><img src={require("../../assets/images/section-4-1.png")} /></div>,
                <div><img src={require("../../assets/images/section-4-2.png")} /></div>,
                <div><img src={require("../../assets/images/section-4-3.png")} /></div>,
                <div><img src={require("../../assets/images/section-4-4.png")} /></div>,
              ]}
      showThumbsOnMount={true}
			// sources={[
			// 	'https://i.imgur.com/fsyrScY.jpg',
			// 	'https://www.youtube.com/watch?v=xshEZzpS4CQ',
			// 	'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
			// ]}
		/>
      </div>

      <div className="column images mobile">
      {newsData?.map((el, index) => {
              return <img src={require(`../../assets/images/${el.img}`)} className='news-image' onClick={() => handleImageOpen(index)} key={`${el.img}-${index}-${el.date}`} />
           })}
      </div>
    </div>
  );
}
