import React from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { SingleActivity } from "../../components/SingleActivity";

export default function Activity() {
  const { topic } = useParams();

  const data = [
    {
      title: "Търговия с газ и електрическа енергия",
      firstSubtitle: "Дейност",
      secondSubtitle: "Ползи",
      firstParagraph:
        "Грей Стоун България изпълнява инвестиционна програма, която включва изграждането на покривни фотовлатични централи както върху частни, така и върху публични сгради.",
      secondParagraph:
        "Възможност да предлагаме на нашите клиенти достъп до по-достъпна и “зелена” електроенергия, без да се налага те да правят каквито и да е разходи за изграждането на подобна централа.",
      image: 'section-2-1.png'
    },
    {
      title: "Инвестиции в изграждането на ВЕИ",
      firstSubtitle: "Дейност",
      secondSubtitle: "Ползи",
      firstParagraph:
        "Грей Стоун България изпълнява инвестиционна програма, която включва изграждането на покривни фотовлатични централи както върху частни, така и върху публични сгради.",
      secondParagraph:
        "Възможност да предлагаме на нашите клиенти достъп до по-достъпна и “зелена” електроенергия, без да се налага те да правят каквито и да е разходи за изграждането на подобна централа. ",
      image: 'section-2-2.png'
    },
    {
      title: "Интелигентни системи за съхранение на електроенергия",
      firstSubtitle: "Дейност",
      secondSubtitle: "Ползи",
      firstParagraph:
        "Грей Стоун България инвестира с развитието на интелигентни системи за съхранение на електроенергия.",
      secondParagraph:
        "В съзвучие с тенденциите за производство на  електроенергия за собствени нужди, ние се стремим да създадем достъпен продукт на конкурентна цена, позволяващ съхранението на неизразходваната енергия и използването й при нужда.",
      image: 'section-2-3.png'
    },
  ];

  const handleActivity = () => {
    if (topic === "trade") {
      return data[0]
    } else if (topic === "energy") {
      return data[1]
    } else if (topic === "storage") {
      return data[2]
    }
  };

  return (
    <div className="screen-wrapper single-activity">
      <SingleActivity data={handleActivity()} />
    </div>
  );
}
