import { BrowserRouter, Routes, Route, Navigate, useLocation, RouterProvider } from "react-router-dom";
import './App.scss';
import { useRef } from "react";
import { scroll } from "./utilities/helpers";
import { Home, News, SingleNews, Activity } from "./screens";
import { Header, Footer } from "./components";

function App() {
  const heroRef = useRef();
  const activityRef = useRef();
  const aboutRef = useRef();

  const checkRef = (ref) => {
    if (ref === 'heroRef') {
      scroll(heroRef)
    }
    else if (ref === 'activityRef') {
      scroll(activityRef)
    }
    else if (ref === 'aboutRef') {
      scroll(aboutRef)
    }
  }

  return (
    <div className="App">
      <BrowserRouter>
      <Header scroll={scroll} checkRef={checkRef} />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/news' element={<News />} />
          <Route path='/single-news' element={<SingleNews />} />
          <Route path='/activity/:topic' element={<Activity />} />
        </Routes>
      <Footer />
      </BrowserRouter> 
    </div>
  );
}

export default App;
