import React from 'react';
import "./styles.scss";

export default function AboutSection({ aboutRef }) {

  const data = [
    { text: 'Създаване на “Грей Стоун България” ООД', date: '2019г', img: 'about-rocket-icon.svg' },
    { text: 'Лиценз за търговия с електрическа енергия - №Л542-15 ', date: '2021г', img: 'about-thunder-icon.svg', link: 'https://graystone.s3.eu-central-1.amazonaws.com/electricity.png' },
    { text: 'Лиценз за търговия с природен газ - №Л582-15', date: '2021г', img: 'about-fire-icon.svg', link: 'https://graystone.s3.eu-central-1.amazonaws.com/gas.png' },
    { text: 'Започва изграждането на мрежа от зарядни станции за електромобили под наименованието WINK CHARGING', date: '2022г', img: 'about-cable-icon.svg' },
    { text: 'Започва изграждането на първата покривна фотоволтаична централа', date: '2022г', img: 'about-panel-icon.svg' }
  ]

  return (
    <div className='section-wrapper about' ref={aboutRef}>
      <div className='text-section-wrapper'>
         <h3>За нас</h3>
         <p className='margin'>
           Грей Стоун България е основана през 2019г. и представлява група от дружества с дейност в енергийния сектор.
         </p>
         <p className='margin'>
           Нашата мисия е да станем водещ участник на пазара посредством предлагането на широк спектър от услуги, стартирайки от производство на зелена електроенергия, търговия на едро и дребно, развитие на мрежа от зарядни станции за електромобили.
         </p>
      </div>

      <div className='timeline-wrapper'>
        {data.map((el, index) => {
          return (
            <div className='timeline-section' key={`${el.date}-${index}`}>
     
               {/* <div className='circle' /> */}
               <img className='icon' src={require(`../../../assets/icons/${el.img}`)} />
               <div className='line' />

               <div className='text-date-wrapper'>
                 {/* <p className='mobile-date'>{el.date}</p> */}
                 <div className='text-wrapper'>
                   <p className='date'>{el.date}</p>
                   <div className='flex' onClick={() => window.open(el?.link)}>
                     <h4 style={{ textDecoration: el?.link ? 'underline' : 'none', cursor: el?.link ? 'pointer' : 'default' }}>{el.text}</h4>
                   </div>
                 </div>
               </div>
               {/* <p className='desktop-date'>{el.date}</p> */}
            </div>
          )
        })}
        <div className='line horizontal' />
      </div>
    </div>
  )
}
