import React from "react";
import "./styles.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ActivitySection({ activityRef }) {
    const navigate = useNavigate();

  return (
    <div className="section-wrapper activity" ref={activityRef}>

      <div className="activity-wrapper">
        <h3>Дейност</h3>

        <div className="cards-wrapper">
           <div className="single-card-wrapper">
             <img src={require('../../../assets/images/section-2-1.png')} />
             <div className="text-wrapper">
               <h4>Търговия</h4>
               <p>Търговия с газ и електрическа енергия</p>
             </div>
             <div className="icon" onClick={() => navigate('/activity/trade')}>
               <img src={require('../../../assets/icons/arrow-icon.svg').default} />
             </div>
           </div>
         
           <div className="single-card-wrapper">
             <img src={require('../../../assets/images/section-2-2.png')} />
             <div className="text-wrapper">
               <h4>ВЕИ</h4>
               <p>Инвестиции в изграждането на ВЕИ</p>
             </div>
             <div className="icon" onClick={() => navigate('/activity/energy')}>
               <img src={require('../../../assets/icons/arrow-icon.svg').default} />
             </div>
           </div>
         
           <div className="single-card-wrapper">
             <img src={require('../../../assets/images/section-2-3.png')} />
             <div className="text-wrapper">
               <h4>Складови стопанства</h4>
               <p>Инвестиции в изграждането на складови стопанства за съхранение на електроенергия</p>
             </div>
             <div className="icon" onClick={() => navigate('/activity/storage')}>
               <img src={require('../../../assets/icons/arrow-icon.svg').default} />
             </div>
           </div>

           <div className="single-card-wrapper">
             <img src={require('../../../assets/images/section-2-4.png')} />
             <div className="text-wrapper">
               <h4>Зарядни станции</h4>
               <p>Инвестиции в изграждането на мрежа от зарядни станции за електромобили</p>
             </div>
             <div className="icon" onClick={() => window.open('https://www.winkcharging.com/')}>
               <img src={require('../../../assets/icons/arrow-icon.svg').default} />
             </div>
           </div>
        </div>
      </div>
    </div>
  );
}
