import React from 'react';
import './styles.scss';

export default function ContactSection({ contactRef }) {
  return (
    <div className='section-wrapper contact' ref={contactRef}>
        <div className='column left'>
        <iframe 
          width='100%' 
          height='100%'
          src="https://api.mapbox.com/styles/v1/ionko/cldsihcum002601o6ptdt6qa4/draft.html?title=false&access_token=pk.eyJ1IjoiaW9ua28iLCJhIjoiY2t6NW05ZnN0MDM2ejJ2czhwYjI0MWRpciJ9.oWIt1dPmBd74WreAoFExwQ&zoomwheel=false#16.28/42.670043/23.316026"
          title="Gray-Stone"
          style={{ border: 'none', borderRadius: '10px' }}>
        </iframe>
        </div>

        <div className='column padding right'>
            <h3>Контакти</h3>

            <div className='rows-wrapper'>
                <div className='row'>
                    <div className='left'>
                      <img src={require('../../../assets/icons/location-icon.svg').default} />
                      <div className='text'>Адрес</div>
                    </div>
                    <div className='right'>
                      <div className='text'>Бул. Джеймс Баучер 103, ет.1, офис 3</div>
                    </div>
                </div>

                <div className='row'>
                    <div className='left'>
                      <img src={require('../../../assets/icons/phone-icon.svg').default} />
                      <div className='text'>Телефон</div>
                    </div>
                    <div className='right'>
                      <div className='text'>02 980 10 59</div>
                    </div>
                </div>

                <div className='row'>
                    <div className='left'>
                      <img src={require('../../../assets/icons/email-icon.svg').default} />
                      <div className='text'>Email</div>
                    </div>
                    <div className='right'>
                      <div className='text'>office@graystone.bg</div>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
  )
}
