import React from 'react';
import './styles.scss';

export default function Card({ photoLink, title, date, onClick }) {
  return (
    <div className="single-card-wrapper" key={`${date}-${photoLink}`}>
      <img src={require(`../../assets/images/${photoLink}`)} />
      <h4>{title}</h4>
  
      <div className="flex-wrapper wide">
        <p>{date}</p>
  
        <div className="icon" onClick={onClick}>
         <img src={require('../../assets/icons/arrow-icon.svg').default} />
        </div>
      </div>
    </div>
  )
}
