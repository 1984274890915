import { createSlice } from '@reduxjs/toolkit';
// import { httpClient } from '../utilities';

const initialState = {
    language: 'bg',
    currentRef: 'heroRef'
}

export const general = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setLanguage: (state, action) => ({ ...state, language: action.payload })
    }
})

export const {
    setLanguage
} = general.actions

export default general.reducer;