import React from 'react';
import './styles.scss';
import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroll } from '../../utilities/helpers';
import { HeroSection, ActivitySection, AboutSection, NewsSection, UsefulInformation, ContactSection } from '../../components';

export default function Home() {
  const heroRef = useRef();
  const activityRef = useRef();
  const aboutRef = useRef();
  const newsRef = useRef();
  const infoRef = useRef();
  const contactRef = useRef();

  const { state } = useLocation();

  useEffect(() => {
    if (state?.ref === 'heroRef') {
      scroll(heroRef)
      state.ref = ''
    }
    else if (state?.ref === 'activityRef') {
      scroll(activityRef)
      state.ref = ''
    }
    else if (state?.ref === 'aboutRef') {
      scroll(aboutRef)
      state.ref = ''
    }
    else if (state?.ref === 'newsRef') {
      scroll(newsRef)
      state.ref = ''
    }
    else if (state?.ref === 'infoRef') {
      scroll(infoRef)
      state.ref = ''
    }
    else if (state?.ref === 'contactRef') {
      scroll(contactRef)
      state.ref = ''
    }
    else {
      window.scrollTo(0, 0)
    }
    console.log('state', state)
  }, [state])
  
  return (
    <div className='screen-wrapper home'>
      <HeroSection heroRef={heroRef} />
      <AboutSection aboutRef={aboutRef} />
      <ActivitySection activityRef={activityRef} />
      <NewsSection newsRef={newsRef} />
      {/* <UsefulInformation infoRef={infoRef} /> */}
      <ContactSection contactRef={contactRef} />
    </div>
  )
}
