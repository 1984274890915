import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function SingleActivity({ data }) {
    const navigate = useNavigate();

    useEffect(() => {
      window.scrollTo(0, { behavour: 'smooth' })
    }, []);

  return (
    <div className='single-activity-wrapper'>
      <div className="top-button-wrapper" onClick={() => navigate(-1)}>
          <img
            src={require("../../assets/icons/arrow-icon-blue.svg").default}
            className="arrow-icon"
          />
          <div className="text">Назад</div>
      </div>

      <div className='columns-wrapper'>
        <div className="column dark center">
        <div className="text-wrapper">
          <h3>{data?.title}</h3>

          <div className='subtitle-text-wrapper'>
            <div className='flex'>
                <img src={require('../../assets/icons/blue-square.svg').default} />
                <h5>{data?.firstSubtitle}</h5>
            </div>
            <p>{data?.firstParagraph}</p>
          </div>

          <div className='subtitle-text-wrapper'>
          <div className='flex'>
                <img src={require('../../assets/icons/blue-square.svg').default} />
                <h5>{data?.secondSubtitle}</h5>
            </div>
            <p>{data?.secondParagraph}</p>
          </div>
        </div>
        </div>
        <div className="column image desktop">
          <img src={require(`../../assets/images/${data?.image}`)} alt='activity' />
        </div>
      </div>
    </div>
  )
}
