import React from 'react';
import './styles.scss';

export default function HeroSection({ heroRef }) {
  return (
    <div className='section-wrapper hero' ref={heroRef}>
        <h1>Грей Стоун България</h1>
        <h2>Предлагаме комплексни решения в сферата на енергийния сектор</h2>
        {/* <img src={require('../../../assets/images/hero-section-illustration-desktop.png')} /> */}
    </div>
  )
}
