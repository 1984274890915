import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../Card';
import { useEffect } from 'react';

export default function NewsSection({ newsRef }) {
  const navigate = useNavigate();

    const newsData = [
        { text: 'Заглавие на новината се изписва тук, като то може да бъде и по-дълго от два реда', date: '12.01.2022', img: 'section-4-1.png' },
        { text: 'Примерно заглавие на новина, която ще бъде свързана с енергия', date: '12.01.2022', img: 'section-4-2.png' },
        { text: 'Заглавие на новината се изписва тук', date: '12.01.2022', img: 'section-4-3.png' },
        { text: 'Примерно заглавие на новина', date: '12.01.2022', img: 'section-4-4.png' }
    ]

  return (
    <div className='section-wrapper news' ref={newsRef}>
      <div className='flex-wrapper wide top'>
        <h3>Новини</h3>

        <div className='flex-wrapper pointer desktop' onClick={() => navigate('/news')}>
          <h5>Виж всички</h5>
          <img src={require('../../../assets/icons/arrow-icon-blue.svg').default} className='arrow-icon' />
        </div>

      </div>

      <div className='cards-wrapper'>
        {newsData.map((el, index) => {
          return <Card photoLink={el.img} title={el.text} date={el.date} onClick={() => navigate('/single-news')} key={`${el.date}-${el.photoLink}-${index}`} />
       })}
      </div>

      <div className='flex-wrapper pointer mobile' onClick={() => navigate('/news')}>
          <h5>Виж всички</h5>
          <img src={require('../../../assets/icons/arrow-icon-blue.svg').default} className='arrow-icon' />
        </div>
    </div>
  )
}
