import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../components';
import { useEffect } from 'react';

export default function News() {
    const navigate = useNavigate();

    const data = [
        { text: 'Заглавие на новината се изписва тук, като то може да бъде и по-дълго от два реда', date: '12.01.2022', img: 'section-4-1.png' },
        { text: 'Примерно заглавие на новина, която ще бъде свързана с енергия', date: '12.01.2022', img: 'section-4-2.png' },
        { text: 'Заглавие на новината се изписва тук', date: '12.01.2022', img: 'section-4-3.png' },
        { text: 'Примерно заглавие на новина', date: '12.01.2022', img: 'section-4-4.png' },
        { text: 'Примерно заглавие на новина, която ще бъде свързана с енергия', date: '10.01.2022', img: 'section-4-2.png' },
        { text: 'Примерно заглавие на новина', date: '11.01.2022', img: 'section-4-4.png' },
        { text: 'Заглавие на новината се изписва тук, като то може да бъде и по-дълго от два реда', date: '08.01.2022', img: 'section-4-1.png' },
        { text: 'Заглавие на новината се изписва тук', date: '08.01.2022', img: 'section-4-3.png' },
    ];

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  return (
    <div className='screen-wrapper news'>
        <div className='flex'>
           <div className="top-button-wrapper" onClick={() => navigate(-1)}>
             <img src={require('../../assets/icons/arrow-icon-blue.svg').default} className='arrow-icon' />
             <div className="text">Назад</div>
           </div>

           <h3>НОВИНИ</h3>
        </div>
        
        <div className='cards-wrapper'>
        {data?.map(el => {
        return <Card photoLink={el.img} title={el.text} date={el.date} onClick={() => navigate('/single-news')} />
      })}
        </div>
    </div>
  )
}
