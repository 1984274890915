import React from 'react';
import './styles.scss';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../actions';

export default function Header(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const language = useSelector(state => state.general.language)
    const [ isNavOpen, setIsNavOpen ] = useState(false);
  
    const navigateOnClick = (ref) => {
      // if (location.pathname !== '/') {
      //   // navigate('/');
      //   navigate('/', { state: { ref } });
      // }
      // else {
      //   props.scroll(ref);
      // }

      navigate('/', { state: { ref } });
      
    }

    const handleLanguage = (lang) => dispatch(setLanguage(lang))

  return (
    <div className='header-wrapper'>
      <div className="mobile">
        <div className={`nav ${isNavOpen ? 'opened' : ''}`}>
          {!isNavOpen &&
          <img 
          src={require('../../assets/icons/menu-icon.svg').default}
          className="nav-mobile"
          onClick={() => setIsNavOpen(true)} />}

          {isNavOpen && 
          <>
          <img src={require('../../assets/icons/close-icon.svg').default}
          onClick={() => setIsNavOpen(false)} />

          <div className="opened-nav">
          <li onClick={() => {
            setIsNavOpen(false)
            navigateOnClick('aboutRef')
              }}>За нас</li>
            <li onClick={() => {
            setIsNavOpen(false)
            navigateOnClick('activityRef')
              }}>Дейност</li>
            <li onClick={() => {
            setIsNavOpen(false)
            navigateOnClick('newsRef')
              }}>Новини</li>
            {/* <li onClick={() => {
            setIsNavOpen(false)
            navigateOnClick('infoRef')
              }}>Полезна информация</li> */}
            <li onClick={() => {
            setIsNavOpen(false)
            navigateOnClick('contactRef')
              }}>Контакти</li>

            <div className='language-wrapper'>
              <span className={`${language === 'bg' ? 'bold' : 'light'}`} onClick={() => handleLanguage('bg')}>БГ</span> | <span className={`${language === 'en' ? 'bold' : 'light'}`} onClick={() => handleLanguage('en')}>EN</span>
            </div>
          </div>
          </>}
        </div>

        {isNavOpen && <div className="background-wrapper"></div>}
        {!isNavOpen && <img className='logo' src={require('../../assets/images/logo.png')} onClick={() => navigateOnClick('')} />}
      </div>

        <div className="header-section logo" onClick={() => navigateOnClick('')}>
          <img src={require('../../assets/images/logo.png')} />
        </div>

        <div className="header-section nav">
          <ul className='nav'>
          <li 
          onClick={() => {
            setIsNavOpen(false)
            navigateOnClick('aboutRef')
          }}
          >За нас</li>
          <li 
          onClick={() => {
            setIsNavOpen(false)
            navigateOnClick('activityRef')
          }}
          >Дейност</li>
          <li 
          onClick={() => {
            setIsNavOpen(false)
            navigateOnClick('newsRef')
          }}
          >Новини</li>
          {/* <li 
          onClick={() => {
            setIsNavOpen(false)
            navigateOnClick('infoRef')
          }}
          >Полезна информация</li> */}
          <li 
          onClick={() => {
            setIsNavOpen(false)
            navigateOnClick('contactRef')
          }}
          >Контакти</li>
          </ul>
        </div>

        <div className="header-section language">
          <div className='language-wrapper'>
            <span className={`${language === 'bg' ? 'bold' : 'light'}`} onClick={() => handleLanguage('bg')}>БГ</span> | <span className={`${language === 'en' ? 'bold' : 'light'}`} onClick={() => handleLanguage('en')}>EN</span>
          </div>
        </div>
    </div>
  )
}
